
import mutation from '~/queries/mutations/subscribeToProductEmailNotification.graphql';

export default {
    props: {
        uid: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            show: false,
            email: '',
            disabled: false,
        };
    },
    computed: {},
    methods: {
        toggleForm() {
            if (!this.disabled) {
                this.show = !this.show;
            }
        },
        async submit() {
            try {
                await this.$apollo.mutate({
                    mutation,
                    variables: {
                        input: {
                            email: this.email,
                            /* eslint-disable camelcase */
                            product_uid: this.uid,
                            notification_code: 'back_in_stock',
                            /* eslint-enable camelcase */
                        },
                    },
                });
                this.$notify.success(this.$t('productemailsubscribe.success'));
                this.disabled = true;
                this.show = false;
            } catch (e) {
                this.$notify.error(e);
            }
        },
    },
};
