import { render, staticRenderFns } from "./SubscribeOutOfStock.vue?vue&type=template&id=118f2059&"
import script from "./SubscribeOutOfStock.vue?vue&type=script&lang=js&"
export * from "./SubscribeOutOfStock.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OpusButton: require('/~/src/modules/opus/components/Form/Button.vue').default,OpusRenderInput: require('/~/src/modules/opus/components/Form/RenderInput.vue').default})
