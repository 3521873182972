
export default {
    props: {
        product: {
            type: Object,
            required: true,
        },
    },
    computed: {
        status() {
            if (this.product.is_available_on_demand) {
                return {
                    color: 'text-warning',
                    icon: 'check-outline',
                    label: this.$t('catalog.stock.available.ondemand'),
                };
            }

            if (this.product.stock_status === 'IN_STOCK') {
                const status = {
                    icon: 'check-outline',
                };

                if (this.product.only_x_left_in_stock) {
                    status.color = 'text-warning';
                    if (this.product.only_x_left_in_stock >= this.$store.state.auth.stockWarning) {
                        status.label = this.$tc('catalog.stock.only_x_left', this.product.only_x_left_in_stock);
                    } else {
                        status.label = this.$tc('catalog.stock.only_x_left_warning', this.$store.state.auth.stockWarning);
                    }
                } else {
                    status.color = 'text-accent';
                    status.label = this.$t('catalog.stock.available');
                }

                return status;
            } else {
                return {
                    color: 'text-text-red',
                    icon: 'close',
                    label: this.$t('catalog.stock.available.not'),
                };
            }
        },
    },
};
